<template>
  <div class="fragment">
    <ProfitReportTotalHead/>

    <div class="detail-page">
      <div class="detail-page__left">
        <CardLeftBlock
            class="clear-992 height-search-engine"
            :backgroundImage="'balance'"
        >
          <template slot="body">
            <div class="profit-report">
              <div class="profit-report__caption"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['profitReport_TotalAccount'])"></div>
                {{$t('profitReport_TotalAccount.localization_value.value')}}
              </div>
              <div class="profit-report__title">
                ${{getTotal}}
              </div>
              <div class="site-table-mobile__row custom-row">
                <div class="site-table-mobile__piece custom-col custom-col--33 d-flex flex-column"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['profitReport_TotalSum'])"></div>
                  {{$t('profitReport_TotalSum.localization_value.value')}}
                  <span class="mt-1">
                    ${{getTotalSum}}
                  </span>
                </div>
                <div class="site-table-mobile__piece custom-col custom-col--33 d-flex flex-column"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['profitReport_OtherIncome'])"></div>
                  {{$t('profitReport_OtherIncome.localization_value.value')}}
                  <span class="mt-1">
                    <ValueHelper
                        :value="dataReports.income.sum"
                        :replaceValue="'$ 0'"
                        :costType="true"
                        :costCurrencyType="'dollar'"
                    />
                  </span>
                </div>
                <div class="site-table-mobile__piece custom-col custom-col--33 d-flex flex-column"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['profitReport_Expenses'])"></div>
                  {{$t('profitReport_Expenses.localization_value.value')}}
                  <span class="mt-1">
                    <ValueHelper
                        :value="dataReports.expense.sum"
                        :replaceValue="'$ 0'"
                        :costType="true"
                        :costCurrencyType="'dollar'"
                    />
                  </span>
                </div>
              </div>

              <div class="custom-row">
                <div class="custom-col"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['profitReport_TotalSum','profitReport_Period'])"></div>
<!--                  <RadioDefault-->
<!--                      :label="$t('profitReport_TotalSum.localization_value.value')"-->
<!--                      :name="'nameRadio'"-->
<!--                      class="mb-2"-->
<!--                      @change.native="changeRadio(1)"-->
<!--                  />-->
<!--                  <RadioDefault-->
<!--                      :label="$t('profitReport_Period.localization_value.value')"-->
<!--                      :name="'nameRadio'"-->
<!--                      class="mb-2"-->
<!--                      :value="true"-->
<!--                      @change.native="changeRadio(2)"-->
<!--                  />-->
                  <div class="custom-row">
                    <div class="custom-col">
                      <DatePickerDefault
                          :value="dataLeftBlock.filterDateStart"
                          class="date-picker-time-custom  profit-report__dp"
                      >
<!--                        spacer-->
                        <template slot="body">
                          <date-picker
                              v-model="dataLeftBlock.filterDate"
                              ref="datePicker"
                              valueType="format"
                              :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                              :format="'MM/DD/YY'"
                              :placeholder="'mm/dd/yy'"
                              range
                              :disabled="dataLeftBlock.filterType === 1 ? true : false"
                          ></date-picker>
                        </template>
                      </DatePickerDefault>
                    </div>

<!--                    <div class="custom-col custom-col&#45;&#45;50">-->
<!--                      <DatePickerDefault-->
<!--                          :value="dataLeftBlock.filterDateEnd"-->
<!--                          class="date-picker-time-custom  profit-report__dp"-->
<!--                      >-->
<!--                        <template slot="body">-->
<!--                          <date-picker-->
<!--                              v-model="dataLeftBlock.filterDateEnd"-->
<!--                              ref="datePicker"-->
<!--                              valueType="format"-->
<!--                              :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"-->
<!--                              :format="'MM/DD/YY'"-->
<!--                              :placeholder="'mm/dd/yy'"-->
<!--                              :disabled="dataLeftBlock.filterType === 1 ? true : false"-->
<!--                          ></date-picker>-->
<!--                        </template>-->
<!--                      </DatePickerDefault>-->
<!--                    </div>-->
                  </div>

                  <div class="custom-row">
                    <div class="custom-col"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['profitReport_Filter'])"></div>
                      <MainButton
                          :value="$t('profitReport_Filter.localization_value.value')"
                          @click.native="$emit('filter', true)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </CardLeftBlock>
      </div>

      <div class="detail-page__right">
        <ProfitReportTotalTable
            :dataReports="dataReports"
            :dataLeftBlock="dataLeftBlock"
        />
      </div>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import ProfitReportTotalHead from "./ProfitReportTotalHead/ProfitReportTotalHead";
  import ProfitReportTotalTable from "./ProfitReportTotalTable/ProfitReportTotalTable";
  import DatePickerDefault from "../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  // import RadioDefault from "../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "ProfitReportTotalAdmin",
    components: {
      ValueHelper,
      MainButton,
      CardLeftBlock,
      ProfitReportTotalHead,
      ProfitReportTotalTable,
      DatePickerDefault,
      DatePicker,
      // RadioDefault,
    },

    props: {
      dataReports: Object,
      dataLeftBlock: Object,
    },

    computed: {
      getTotal() {
        let sum = Number(this.getTotalSum) + Number(this.dataReports.income.sum) - Number(this.dataReports.expense.sum)

        return parseFloat(sum).toFixed(2)
      },

      getTotalSum() {
        let sum = Number(this.dataReports.transaction.sum) +
            Number(this.dataReports.order_fba.sum) +
            Number(this.dataReports.order_fbm.sum) +
            Number(this.dataReports.order_express_dhl.sum) +
            Number(this.dataReports.order_express_fedex.sum) +
            Number(this.dataReports.balance_output.sum) +
            Number(this.dataReports.disposal.sum) +
            // Number(this.dataReports.expense.sum) +
            // Number(this.dataReports.income.sum) +
            // Number(this.dataReports.insurance.sum) +
            Number(this.dataReports.skladusa_insurance.sum) +
            Number(this.dataReports.other_invoice.sum) +
            Number(this.dataReports.other_packing_invoice.sum) +
            Number(this.dataReports.packaging_fba.sum) +
            Number(this.dataReports.packaging_fbm.sum) +
            Number(this.dataReports.return.sum)
        return parseFloat(sum).toFixed(2)
      }
    },

    data(){
      return{
        options: [{}],
        date: '',
        radioVal: 1,
        timeStart: '',
        timeEnd: '',
      }
    },

    methods: {
      changeRadio(val){
        this.dataLeftBlock.filterType = val
      }
    },
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../scss/mixins/mixins";
  @import "../../../../../../scss/colors";

  .profit-report{
    max-width: 400px;
    width: 100%;

    &__caption{
      font-size: 14px;
      line-height: 21px;
      color: $borderBrown;
    }

    &__title{
      color: $black;
      font-weight: bold;
      line-height: 149.69%;
      font-size: 48px;

      @include for-992{
        font-size: 24px;
        margin-bottom: 20px;
        line-height: normal;
      }
    }

    &__dp{
      border-radius: 6px;
      background: white;
    }
  }


</style>
