<template>
  <div class="head-totally-block">
    <div class="head-totally-block__inner">
      <div class="head-totally-block__item totally">
        <slot name="title">

        </slot>
      </div>

      <div class="head-totally-block__list">
        <slot name="item">

        </slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TotallyBlockHead"
  }
</script>

<style lang="scss">
  @import '../../../scss/colors';
  @import '../../../scss/mixins/mixins';

  .head-totally-block{
    margin: -8px 0 -8px -8px;
    overflow: hidden;
    position: relative;
    width: 100%;

    &__inner{
      display: flex;
      width: calc(100% + 8px);
    }
    &__list{
      display: flex;
      overflow-x: auto;
      width: 100%;

      &::-webkit-scrollbar-track {
        background: transparent;
        margin-right: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: #BBAD9C;
      }

      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        border: 1px;
      }
    }

    &__item{
      font-size: 14px;
      line-height: 16px;
      color: $borderBrown;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 29px;
      position: relative;
      white-space: nowrap;

      @include for-1600{
        padding: 0 15px;
        font-size: 12px;
        line-height: normal;
      }

      span{
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: $black;
        white-space: nowrap;

        @include for-1600{
          font-size: 14px;
          line-height: normal;
        }
      }

      &:after{
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 18px;
        background: $borderBrown;
        opacity: .3;
      }

      &:first-child,
      &:last-child{

        &:after{
          display: none;
        }
      }
    }

    &__item.totally{
      min-width: 159px;
      max-width: 159px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 69px;
      background: $borderBrown;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: $mainBg;

      @include for-1600{
        min-width: initial;
        max-width: initial;
        height: 56px;
        padding: 0 15px;
        font-size: 18px;
        line-height: normal;
      }

      @include for-992{
        height: 40px;
        font-size: 16px;
      }
    }

  }

</style>