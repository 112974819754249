<template>
  <ShippingReportTableUser/>
</template>

<script>
  import ShippingReportTableUser from "./ShippingReportTableUser/ShippingReportTableUser";

  export default {
    name: "ShippingReportTable",
    components: {
      ShippingReportTableUser,
    }
  }
</script>

<style scoped>

</style>