<template>
  <div class="site-table-mobile__inner custom-row">
    <div class="custom-col custom-col--50 custom-col--sm-100">
      <div class="site-table-mobile__item">
        <div class="site-table-mobile__head d-flex align-center">
          <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['profitReport_Transaction'])"></div>
            {{ $t('profitReport_Transaction.localization_value.value') }}
          </div>
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_TotalSum.localization_value.value')}} $
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.transaction.sum"
                :replaceValue="'$ 0'"
                :costType="true"
                :costCurrencyType="'dollar'"
            />
          </span>
          </div>
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_NumberOfOrders.localization_value.value')}}
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.transaction.count"
            />
          </span>
          </div>
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col">
            <LinkButton
                class="accent"
                :value="$t('profitReport_ShowTransactions.localization_value.value')"
                :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=transaction'"
                :type="'dollar2'"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="custom-col custom-col--50 custom-col--sm-100">
      <div class="site-table-mobile__item">
        <div class="site-table-mobile__head d-flex align-center">
          {{ $t('profitReport_orderFba.localization_value.value') }}
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_TotalSum.localization_value.value')}} $
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.order_fba.sum"
                :replaceValue="'$ 0'"
                :costType="true"
                :costCurrencyType="'dollar'"
            />
          </span>
          </div>
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_NumberOfOrders.localization_value.value')}}
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.order_fba.count"
            />
          </span>
          </div>
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col">
            <LinkButton
                class="accent"
                :value="$t('profitReport_ShowTransactions.localization_value.value')"
                :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=order_fba'"
                :type="'dollar2'"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="custom-col custom-col--50 custom-col--sm-100">
      <div class="site-table-mobile__item">
        <div class="site-table-mobile__head d-flex align-center">
          {{ $t('profitReport_orderFbm.localization_value.value') }}
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_TotalSum.localization_value.value')}} $
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.order_fbm.sum"
                :replaceValue="'$ 0'"
                :costType="true"
                :costCurrencyType="'dollar'"
            />
          </span>
          </div>
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_NumberOfOrders.localization_value.value')}}
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.order_fbm.count"
            />
          </span>
          </div>
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col">
            <LinkButton
                class="accent"
                :value="$t('profitReport_ShowTransactions.localization_value.value')"
                :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=order_fbm'"
                :type="'dollar2'"
            />
          </div>
        </div>
      </div>
    </div>


    <div class="custom-col custom-col--50 custom-col--sm-100">
      <div class="site-table-mobile__item">
        <div class="site-table-mobile__head d-flex align-center">
          {{ $t('profitReport_orderExpressDhl.localization_value.value') }}
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_TotalSum.localization_value.value')}} $
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.order_express_dhl.sum"
                :replaceValue="'$ 0'"
                :costType="true"
                :costCurrencyType="'dollar'"
            />
          </span>
          </div>
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_NumberOfOrders.localization_value.value')}}
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.order_express_dhl.count"
            />
          </span>
          </div>
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col">
            <LinkButton
                class="accent"
                :value="$t('profitReport_ShowTransactions.localization_value.value')"
                :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=order_express_dhl'"
                :type="'dollar2'"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="custom-col custom-col--50 custom-col--sm-100">
      <div class="site-table-mobile__item">
        <div class="site-table-mobile__head d-flex align-center">
          {{ $t('profitReport_orderExpressFedex.localization_value.value') }}
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_TotalSum.localization_value.value')}} $
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.order_express_fedex.sum"
                :replaceValue="'$ 0'"
                :costType="true"
                :costCurrencyType="'dollar'"
            />
          </span>
          </div>
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_NumberOfOrders.localization_value.value')}}
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.order_express_fedex.count"
            />
          </span>
          </div>
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col">
            <LinkButton
                class="accent"
                :value="$t('profitReport_ShowTransactions.localization_value.value')"
                :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=order_express_fedex'"
                :type="'dollar2'"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="custom-col custom-col--50 custom-col--sm-100">
      <div class="site-table-mobile__item">
        <div class="site-table-mobile__head d-flex align-center">
          {{ $t('profitReport_orderExpressTNT.localization_value.value') }}
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_TotalSum.localization_value.value')}} $
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.order_express_tnt.sum"
                :replaceValue="'$ 0'"
                :costType="true"
                :costCurrencyType="'dollar'"
            />
          </span>
          </div>
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_NumberOfOrders.localization_value.value')}}
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.order_express_tnt.count"
            />
          </span>
          </div>
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col">
            <LinkButton
                class="accent"
                :value="$t('profitReport_ShowTransactions.localization_value.value')"
                :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=order_express_tnt'"
                :type="'dollar2'"
            />
          </div>
        </div>
      </div>
    </div>



    <div class="custom-col custom-col--50 custom-col--sm-100">
      <div class="site-table-mobile__item">
        <div class="site-table-mobile__head d-flex align-center">
          {{ $t('profitReport_fundsWithdrowal.localization_value.value') }}
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_TotalSum.localization_value.value')}} $
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.balance_output.sum"
                :replaceValue="'$ 0'"
                :costType="true"
                :costCurrencyType="'dollar'"
            />
          </span>
          </div>
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_NumberOfOrders.localization_value.value')}}
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.balance_output.count"
            />
          </span>
          </div>
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col">
            <LinkButton
                class="accent"
                :value="$t('profitReport_ShowTransactions.localization_value.value')"
                :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=balance_output'"
                :type="'dollar2'"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="custom-col custom-col--50 custom-col--sm-100">
      <div class="site-table-mobile__item">
        <div class="site-table-mobile__head d-flex align-center">
          {{ $t('profitReport_disposal.localization_value.value') }}
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_TotalSum.localization_value.value')}} $
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.disposal.sum"
                :replaceValue="'$ 0'"
                :costType="true"
                :costCurrencyType="'dollar'"
            />
          </span>
          </div>
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_NumberOfOrders.localization_value.value')}}
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.disposal.count"
            />
          </span>
          </div>
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col">
            <LinkButton
                class="accent"
                :value="$t('profitReport_ShowTransactions.localization_value.value')"
                :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=disposal'"
                :type="'dollar2'"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="custom-col custom-col--50 custom-col--sm-100">
      <div class="site-table-mobile__item">
        <div class="site-table-mobile__head d-flex align-center">
          {{ $t('profitReport_expense.localization_value.value') }}
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_TotalSum.localization_value.value')}} $
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.expense.sum"
                :replaceValue="'$ 0'"
                :costType="true"
                :costCurrencyType="'dollar'"
            />
          </span>
          </div>
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_NumberOfOrders.localization_value.value')}}
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.expense.count"
            />
          </span>
          </div>
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col">
            <LinkButton
                class="accent"
                :value="$t('profitReport_ShowTransactions.localization_value.value')"
                :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=expense'"
                :type="'dollar2'"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="custom-col custom-col--50 custom-col--sm-100">
      <div class="site-table-mobile__item">
        <div class="site-table-mobile__head d-flex align-center">
          {{ $t('profitReport_income.localization_value.value') }}
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_TotalSum.localization_value.value')}} $
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.income.sum"
                :replaceValue="'$ 0'"
                :costType="true"
                :costCurrencyType="'dollar'"
            />
          </span>
          </div>
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_NumberOfOrders.localization_value.value')}}
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.income.count"
            />
          </span>
          </div>
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col">
            <LinkButton
                class="accent"
                :value="$t('profitReport_ShowTransactions.localization_value.value')"
                :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=income'"
                :type="'dollar2'"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="custom-col custom-col--50 custom-col--sm-100">
      <div class="site-table-mobile__item">
        <div class="site-table-mobile__head d-flex align-center">
          {{ $t('profitReport_insurance.localization_value.value') }}
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_TotalSum.localization_value.value')}} $
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.insurance.sum"
                :replaceValue="'$ 0'"
                :costType="true"
                :costCurrencyType="'dollar'"
            />
          </span>
          </div>
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_NumberOfOrders.localization_value.value')}}
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.insurance.count"
            />
          </span>
          </div>
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col">
            <LinkButton
                class="accent"
                :value="$t('profitReport_ShowTransactions.localization_value.value')"
                :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=insurance'"
                :type="'dollar2'"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="custom-col custom-col--50 custom-col--sm-100">
      <div class="site-table-mobile__item">
        <div class="site-table-mobile__head d-flex align-center">
          {{ $t('profitReport_insuranceSkladusa.localization_value.value') }}
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_TotalSum.localization_value.value')}} $
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.skladusa_insurance.sum"
                :replaceValue="'$ 0'"
                :costType="true"
                :costCurrencyType="'dollar'"
            />
          </span>
          </div>
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_NumberOfOrders.localization_value.value')}}
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.skladusa_insurance.count"
            />
          </span>
          </div>
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col">
            <LinkButton
                class="accent"
                :value="$t('profitReport_ShowTransactions.localization_value.value')"
                :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=skladusa_insurance'"
                :type="'dollar2'"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="custom-col custom-col--50 custom-col--sm-100">
      <div class="site-table-mobile__item">
        <div class="site-table-mobile__head d-flex align-center">
          {{ $t('profitReport_otherInvoice.localization_value.value') }}
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_TotalSum.localization_value.value')}} $
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.other_invoice.sum"
                :replaceValue="'$ 0'"
                :costType="true"
                :costCurrencyType="'dollar'"
            />
          </span>
          </div>
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_NumberOfOrders.localization_value.value')}}
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.other_invoice.count"
            />
          </span>
          </div>
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col">
            <LinkButton
                class="accent"
                :value="$t('profitReport_ShowTransactions.localization_value.value')"
                :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=other_invoice'"
                :type="'dollar2'"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="custom-col custom-col--50 custom-col--sm-100">
      <div class="site-table-mobile__item">
        <div class="site-table-mobile__head d-flex align-center">
          {{ $t('profitReport_packagingFba.localization_value.value') }}
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_TotalSum.localization_value.value')}} $
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.packaging_fba.sum"
                :replaceValue="'$ 0'"
                :costType="true"
                :costCurrencyType="'dollar'"
            />
          </span>
          </div>
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_NumberOfOrders.localization_value.value')}}
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.packaging_fba.count"
            />
          </span>
          </div>
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col">
            <LinkButton
                class="accent"
                :value="$t('profitReport_ShowTransactions.localization_value.value')"
                :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=packaging_fba'"
                :type="'dollar2'"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="custom-col custom-col--50 custom-col--sm-100">
      <div class="site-table-mobile__item">
        <div class="site-table-mobile__head d-flex align-center">
          {{ $t('profitReport_packagingFbm.localization_value.value') }}
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_TotalSum.localization_value.value')}} $
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.packaging_fbm.sum"
                :replaceValue="'$ 0'"
                :costType="true"
                :costCurrencyType="'dollar'"
            />
          </span>
          </div>
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_NumberOfOrders.localization_value.value')}}
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.packaging_fbm.count"
            />
          </span>
          </div>
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col">
            <LinkButton
                class="accent"
                :value="$t('profitReport_ShowTransactions.localization_value.value')"
                :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=packaging_fbm'"
                :type="'dollar2'"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="custom-col custom-col--50 custom-col--sm-100">
      <div class="site-table-mobile__item">
        <div class="site-table-mobile__head d-flex align-center">
          {{ $t('profitReport_return.localization_value.value') }}
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_TotalSum.localization_value.value')}} $
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.return.sum"
                :replaceValue="'$ 0'"
                :costType="true"
                :costCurrencyType="'dollar'"
            />
          </span>
          </div>
          <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
            {{$t('profitReport_NumberOfOrders.localization_value.value')}}
            <span class="mt-1">
            <ValueHelper
                :value="dataReports.return.count"
            />
          </span>
          </div>
        </div>
        <div class="site-table-mobile__row custom-row">
          <div class="site-table-mobile__piece custom-col">
            <LinkButton
                class="accent"
                :value="$t('profitReport_ShowTransactions.localization_value.value')"
                :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=return'"
                :type="'dollar2'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";

export default {
  name: "ProfitReportTotalTableMobile",

  components: {LinkButton, ValueHelper},

  props: {
    dataReports: Object,
    dataLeftBlock: Object,
  },

  computed: {
    getFilterDate() {
      return '?start_date=' + this.$moment(this.dataLeftBlock.filterDate[0]).format('YYYY-MM-DD') +
          '&end_date=' + this.$moment(this.dataLeftBlock.filterDate[1]).format('YYYY-MM-DD')
    },
  },
}
</script>

<style scoped>

</style>