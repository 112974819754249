<template>
  <div class="fragment">
    <div class="table-filter-wrap">
      <template v-if="$store.getters.getProfitReport && Object.keys($store.getters.getProfitReport).length > 0 && !$store.getters.getProfitReportLoading">
        <div class="site-table-wrap small-table"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['profitReport_Field','profitReport_TotalSum','profitReport_NumberOfOrders', 'profitReport_ShowTransactions'])"></div>
          <table class="site-table mt-0" v-if="!isMobileFunc()">
            <thead>
            <tr>
              <th>{{$t('profitReport_Field.localization_value.value')}}</th>
              <th class="text-lg-right">{{$t('profitReport_TotalSum.localization_value.value')}}</th>
              <th class="text-lg-right">{{$t('profitReport_NumberOfOrders.localization_value.value')}}</th>
              <th width="100%"></th>
              <th class="right pr-1"></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <div class="table-row">
                  <div
                      v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['profitReport_Transaction'])"></div>
                    {{$t('profitReport_Transaction.localization_value.value')}}
                  </div>
                </div>
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.transaction.sum"
                    :replaceValue="'$ 0'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.transaction.count"
                />
              </td>
              <td>
              </td>
              <td align="right" class="pr-1">
                <LinkButton
                    class="accent"
                    :value="$t('profitReport_ShowTransactions.localization_value.value')"
                    :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=transaction'"
                    :type="'dollar2'"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="table-row">
                  <div
                      v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['profitReport_orderFba'])"></div>
                    {{$t('profitReport_orderFba.localization_value.value')}}
                  </div>
                </div>
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.order_fba.sum"
                    :replaceValue="'$ 0'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.order_fba.count"
                />
              </td>
              <td>
              </td>
              <td align="right" class="pr-1">
                <LinkButton
                    class="accent"
                    :value="$t('profitReport_ShowTransactions.localization_value.value')"
                    :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=order_fba'"
                    :type="'dollar2'"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="table-row">
                  <div
                      v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['profitReport_orderFbm'])"></div>
                    {{$t('profitReport_orderFbm.localization_value.value')}}
                  </div>
                </div>
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.order_fbm.sum"
                    :replaceValue="'$ 0'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.order_fbm.count"
                />
              </td>
              <td>
              </td>
              <td align="right" class="pr-1">
                <LinkButton
                    class="accent"
                    :value="$t('profitReport_ShowTransactions.localization_value.value')"
                    :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=order_fbm'"
                    :type="'dollar2'"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="table-row">
                  <div
                      v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['profitReport_orderExpressDhl'])"></div>
                    {{$t('profitReport_orderExpressDhl.localization_value.value')}}
                  </div>
                </div>
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.order_express_dhl.sum"
                    :replaceValue="'$ 0'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.order_express_dhl.count"
                />
              </td>
              <td>
              </td>
              <td align="right" class="pr-1">
                <LinkButton
                    class="accent"
                    :value="$t('profitReport_ShowTransactions.localization_value.value')"
                    :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=order_express_dhl'"
                    :type="'dollar2'"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="table-row">
                  <div
                      v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['profitReport_orderExpressFedex'])"></div>
                    {{$t('profitReport_orderExpressFedex.localization_value.value')}}
                  </div>
                </div>
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.order_express_fedex.sum"
                    :replaceValue="'$ 0'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.order_express_fedex.count"
                />
              </td>
              <td>
              </td>
              <td align="right" class="pr-1">
                <LinkButton
                    class="accent"
                    :value="$t('profitReport_ShowTransactions.localization_value.value')"
                    :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=order_express_fedex'"
                    :type="'dollar2'"
                />
              </td>
            </tr>
<!--            <tr>-->
<!--              <td>-->
<!--                <div class="table-row">-->
<!--                  <div-->
<!--                      v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                    <div class="admin-edit" @click="editTranslate(['profitReport_orderExpressTNT'])"></div>-->
<!--                    {{$t('profitReport_orderExpressTNT.localization_value.value')}}-->
<!--                  </div>-->
<!--                </div>-->
<!--              </td>-->
<!--              <td align="right">-->
<!--                <ValueHelper-->
<!--                    :value="dataReports.order_express_tnt.sum"-->
<!--                    :replaceValue="'$ 0'"-->
<!--                    :costType="true"-->
<!--                    :costCurrencyType="'dollar'"-->
<!--                />-->
<!--              </td>-->
<!--              <td align="right">-->
<!--                <ValueHelper-->
<!--                    :value="dataReports.order_express_tnt.count"-->
<!--                />-->
<!--              </td>-->
<!--              <td>-->
<!--              </td>-->
<!--              <td align="right" class="pr-1">-->
<!--                <LinkButton-->
<!--                    class="accent"-->
<!--                    :value="$t('profitReport_ShowTransactions.localization_value.value')"-->
<!--                    :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=order_express_tnt'"-->
<!--                    :type="'dollar2'"-->
<!--                />-->
<!--              </td>-->
<!--            </tr>-->
            <tr>
              <td>
                <div class="table-row">
                  <div
                      v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['profitReport_fundsWithdrowal'])"></div>
                    {{$t('profitReport_fundsWithdrowal.localization_value.value')}}
                  </div>
                </div>
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.balance_output.sum"
                    :replaceValue="'$ 0'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.balance_output.count"
                />
              </td>
              <td>
              </td>
              <td align="right" class="pr-1">
                <LinkButton
                    class="accent"
                    :value="$t('profitReport_ShowTransactions.localization_value.value')"
                    :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=balance_output'"
                    :type="'dollar2'"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="table-row">
                  <div
                      v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['profitReport_fundsPayoneer'])"></div>
                    {{$t('profitReport_fundsPayoneer.localization_value.value')}}
                  </div>
                </div>
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.payoneer_commission.sum"
                    :replaceValue="'$ 0'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.payoneer_commission.count"
                />
              </td>
              <td>
              </td>
              <td align="right" class="pr-1">
                <LinkButton
                    class="accent"
                    :value="$t('profitReport_ShowTransactions.localization_value.value')"
                    :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=payoneer_commission'"
                    :type="'dollar2'"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="table-row"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['profitReport_disposal'])"></div>
                  {{$t('profitReport_disposal.localization_value.value')}}
                </div>
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.disposal.sum"
                    :replaceValue="'$ 0'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.disposal.count"
                />
              </td>
              <td>
              </td>
              <td align="right" class="pr-1">
                <LinkButton
                    class="accent"
                    :value="$t('profitReport_ShowTransactions.localization_value.value')"
                    :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=disposal'"
                    :type="'dollar2'"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="table-row"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['profitReport_expense'])"></div>
                  {{$t('profitReport_expense.localization_value.value')}}
                </div>
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.expense.sum"
                    :replaceValue="'$ 0'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.expense.count"
                />
              </td>
              <td>
              </td>
              <td align="right" class="pr-1">
                <LinkButton
                    class="accent"
                    :value="$t('profitReport_ShowTransactions.localization_value.value')"
                    :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=expense'"
                    :type="'dollar2'"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="table-row"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['profitReport_income'])"></div>
                  {{$t('profitReport_income.localization_value.value')}}
                </div>
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.income.sum"
                    :replaceValue="'$ 0'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.income.count"
                />
              </td>
              <td>
              </td>
              <td align="right" class="pr-1">
                <LinkButton
                    class="accent"
                    :value="$t('profitReport_ShowTransactions.localization_value.value')"
                    :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=income'"
                    :type="'dollar2'"
                />
              </td>
            </tr>
<!--            <tr>-->
<!--              <td>-->
<!--                <div class="table-row"-->
<!--                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                  <div class="admin-edit" @click="editTranslate(['profitReport_insurance'])"></div>-->
<!--                  {{$t('profitReport_insurance.localization_value.value')}}-->
<!--                </div>-->
<!--              </td>-->
<!--              <td align="right">-->
<!--                <ValueHelper-->
<!--                    :value="dataReports.insurance.sum"-->
<!--                    :replaceValue="'$ 0'"-->
<!--                    :costType="true"-->
<!--                    :costCurrencyType="'dollar'"-->
<!--                />-->
<!--              </td>-->
<!--              <td align="right">-->
<!--                <ValueHelper-->
<!--                    :value="dataReports.insurance.count"-->
<!--                />-->
<!--              </td>-->
<!--              <td>-->
<!--              </td>-->
<!--              <td align="right" class="pr-1">-->
<!--                <LinkButton-->
<!--                    class="accent"-->
<!--                    :value="$t('profitReport_ShowTransactions.localization_value.value')"-->
<!--                    :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=insurance'"-->
<!--                    :type="'dollar2'"-->
<!--                />-->
<!--              </td>-->
<!--            </tr>-->
            <tr>
              <td>
                <div class="table-row"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['profitReport_insurance'])"></div>
                  {{$t('profitReport_insuranceSkladusa.localization_value.value')}}
                </div>
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.skladusa_insurance.sum"
                    :replaceValue="'$ 0'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.skladusa_insurance.count"
                />
              </td>
              <td>
              </td>
              <td align="right" class="pr-1">
                <LinkButton
                    class="accent"
                    :value="$t('profitReport_ShowTransactions.localization_value.value')"
                    :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=skladusa_insurance'"
                    :type="'dollar2'"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="table-row"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['profitReport_otherInvoice'])"></div>
                  {{$t('profitReport_otherInvoice.localization_value.value')}}
                </div>
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.other_invoice.sum"
                    :replaceValue="'$ 0'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.other_invoice.count"
                />
              </td>
              <td>
              </td>
              <td align="right" class="pr-1">
                <LinkButton
                    class="accent"
                    :value="$t('profitReport_ShowTransactions.localization_value.value')"
                    :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=other_invoice'"
                    :type="'dollar2'"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="table-row"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['profitReport_otherPackingInvoice'])"></div>
                  {{$t('profitReport_otherPackingInvoice.localization_value.value')}}
                </div>
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.other_packing_invoice.sum"
                    :replaceValue="'$ 0'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.other_packing_invoice.count"
                />
              </td>
              <td>
              </td>
              <td align="right" class="pr-1">
                <LinkButton
                    class="accent"
                    :value="$t('profitReport_ShowTransactions.localization_value.value')"
                    :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=other_packing_invoice'"
                    :type="'dollar2'"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="table-row"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['profitReport_packagingFba'])"></div>
                  {{$t('profitReport_packagingFba.localization_value.value')}}
                </div>
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.packaging_fba.sum"
                    :replaceValue="'$ 0'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.packaging_fba.count"
                />
              </td>
              <td>
              </td>
              <td align="right" class="pr-1">
                <LinkButton
                    class="accent"
                    :value="$t('profitReport_ShowTransactions.localization_value.value')"
                    :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=packaging_fba'"
                    :type="'dollar2'"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="table-row"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['profitReport_packagingFbm'])"></div>
                  {{$t('profitReport_packagingFbm.localization_value.value')}}
                </div>
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.packaging_fbm.sum"
                    :replaceValue="'$ 0'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.packaging_fbm.count"
                />
              </td>
              <td>
              </td>
              <td align="right" class="pr-1">
                <LinkButton
                    class="accent"
                    :value="$t('profitReport_ShowTransactions.localization_value.value')"
                    :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=packaging_fbm'"
                    :type="'dollar2'"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="table-row"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['profitReport_return'])"></div>
                  {{$t('profitReport_return.localization_value.value')}}
                </div>
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.return.sum"
                    :replaceValue="'$ 0'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="dataReports.return.count"
                />
              </td>
              <td>
              </td>
              <td align="right" class="pr-1">
                <LinkButton
                    class="accent"
                    :value="$t('profitReport_ShowTransactions.localization_value.value')"
                    :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type=return'"
                    :type="'dollar2'"
                />
              </td>
            </tr>
            </tbody>
          </table>

          <div class="site-table-mobile"
               v-if="isMobileFunc()"
          >

              <ProfitReportTotalTableMobile
                  :dataReports="dataReports"
                  :dataLeftBlock="dataLeftBlock"
              />
<!--              <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sm-100">-->
<!--                <div class="site-table-mobile__item">-->
<!--                  <div class="site-table-mobile__head d-flex align-center">-->
<!--                    From Transaction 3%-->
<!--                  </div>-->
<!--                  <div class="site-table-mobile__row custom-row">-->
<!--                    <div class="site-table-mobile__piece custom-col custom-col&#45;&#45;50 d-flex flex-column">-->
<!--                      Total Sum, $-->
<!--                      <span class="mt-1">$34785</span>-->
<!--                    </div>-->
<!--                    <div class="site-table-mobile__piece custom-col custom-col&#45;&#45;50 d-flex flex-column">-->
<!--                      Number of Orders-->
<!--                      <span class="mt-1">-</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="site-table-mobile__row custom-row">-->
<!--                    <div class="site-table-mobile__piece custom-col">-->
<!--                      <LinkButton-->
<!--                          class="accent"-->
<!--                          :value="$t('profitReport_ShowTransactions.localization_value.value')"-->
<!--                          :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type='"-->
<!--                          :type="'dollar2'"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sm-100">-->
<!--                <div class="site-table-mobile__item">-->
<!--                  <div class="site-table-mobile__head d-flex align-center">-->
<!--                    From Transaction 3%-->
<!--                  </div>-->
<!--                  <div class="site-table-mobile__row custom-row">-->
<!--                    <div class="site-table-mobile__piece custom-col custom-col&#45;&#45;50 d-flex flex-column">-->
<!--                      Total Sum, $-->
<!--                      <span class="mt-1">$34785</span>-->
<!--                    </div>-->
<!--                    <div class="site-table-mobile__piece custom-col custom-col&#45;&#45;50 d-flex flex-column">-->
<!--                      Number of Orders-->
<!--                      <span class="mt-1">-</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="site-table-mobile__row custom-row">-->
<!--                    <div class="site-table-mobile__piece custom-col">-->
<!--                      <LinkButton-->
<!--                          class="accent"-->
<!--                          :value="$t('profitReport_ShowTransactions.localization_value.value')"-->
<!--                          :link="$store.getters.GET_PATHS.reportsProfitReportTransactions + getFilterDate + '&type='"-->
<!--                          :type="'dollar2'"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

          </div>

        </div>
      </template>

      <!--<ProductsToWarehouse @close="closePopup" v-if="isModalProductsToWarehouse"/>-->


    </div>
  </div>
</template>

<script>
import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import ProfitReportTotalTableMobile
  from "@/components/modules/ProfitReportModule/components/ProfitReportTotal/ProfitReportTotalAdmin/ProfitReportTotalTable/ProfitReportTotalTableMobile/ProfitReportTotalTableMobile";

export default {
  name: "ProfitReportTotalTable",
  components: {
    ProfitReportTotalTableMobile,
    ValueHelper,
    LinkButton,
  },

  mixins: [mixinDetictingMobile],

  props: {
    dataReports: Object,
    dataLeftBlock: Object,
  },

  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      show4: false,
    }
  },

  computed: {
    getFilterDate() {
      return '?start_date=' + this.$moment(this.dataLeftBlock.filterDate[0]).format('YYYY-MM-DD') +
          '&end_date=' + this.$moment(this.dataLeftBlock.filterDate[1]).format('YYYY-MM-DD')
    },
  },

  methods: {
  },

}
</script>

<style lang="scss" scoped>

td {
  padding: 14px 10px !important;
}

td:nth-child(1) {
  min-width: 290px;
  position: relative;
  border-right: 2px solid #e2ddd5;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: #EBE5DD;
    opacity: 0.4;
  }

  > .table-row {
    position: relative;
    z-index: 1;
  }
}

td:nth-child(2) {
  padding-left: 40px;
}

th:last-child,
td:last-child {
  padding-right: 15px !important;
}


</style>
