<template>
  <ProfitReportTableUser/>
</template>

<script>
  import ProfitReportTableUser from "./ProfitReportTableUser/ProfitReportTableUser";

  export default {
    name: "ProfitReportTable",
    components: {
      ProfitReportTableUser,
    }
  }
</script>

<style scoped>

</style>