<template>
  <ProfitReportTransactionsAdmin
      :list="list"
      :total="total"
      :type="type"
  />
</template>

<script>
  import ProfitReportTransactionsAdmin from "./ProfitReportTransactionsAdmin/ProfitReportTransactionsAdmin";
  import {queryFilterMixin} from "@/mixins/routeFilterMixins/queryFilterMixin";

  export default {
    name: "ProfitReportTransactions",
    components: {
      ProfitReportTransactionsAdmin,
    },

    mixins: [queryFilterMixin],

    data() {
      return {
        list: [],
        total: 0,
        type: null,
      }
    },

    mounted() {
      this.initProfitReportTransactions()
    },

    methods: {
      initProfitReportTransactions() {

        let type = this.$route.query.type
        let startDate = this.$route.query.start_date
        let endDate = this.$route.query.end_date

        this.type = type

        if(!type || !startDate || !endDate) return

        let myQuery = this.createQueryBuilderObject()
        // myQuery.where('with_details', 1)
        // myQuery.where('start_date', this.$route.query.start_date )
        // myQuery.where('end_date', this.$route.query.end_date )

        let url = this.generateGetParamsFromQuery(myQuery, 1000, 0)
        // &detail_section=${type}
        url += `&with_details=1&start_date=${startDate}&end_date=${endDate}&type=${type}`
        this.$store.dispatch('fetchProfitReport', url).then(response => {
          let reports = this.getRespData(response)
          let reportItem = reports[type]
          this.list = reportItem.list
          this.total = reportItem.sum
        }).catch(error => this.createErrorLog(error))

      },
    },

  }
</script>

<style scoped>

</style>