<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line ">
      <div class="content-tabs content-tabs--separator">

      </div>
      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">

      <div class="totally-block-wrap">
        <TotallyBlockHead>
          <template slot="title">
            Totally
          </template>
          <template slot="item">
            <div class="head-totally-block__item">
              Transactions Sum
              <span>$1000</span>
            </div>
          </template>
          <template slot="item">
            <div class="head-totally-block__item">
              SkladUSA Fee
              <span>$0</span>
            </div>
          </template>
          <template slot="item">
            <div class="head-totally-block__item">
              PayPal Fee
              <span>$10</span>
            </div>
          </template>
          <template slot="item">
            <div class="head-totally-block__item">
              Shipping Cost
              <span>$32.5</span>
            </div>
          </template>
          <template slot="item">
            <div class="head-totally-block__item">
              Other Expense
              <span>$0</span>
            </div>
          </template>
          <template slot="item">
            <div class="head-totally-block__item">
              Profit
              <span>$950</span>
            </div>
          </template>
        </TotallyBlockHead>
      </div>



      <div class="btn-right-block">
        <router-link :to="$store.getters.GET_PATHS.incomeExpensesNewExpense">
          <MainButton class="btn-fit-content ml-2"
                      :value="'Add New'"
                      :ico="true"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus" />
            </template>
          </MainButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import TotallyBlockHead from "../../../../../../coreComponents/TotallyBlockHead/TotallyBlockHead";


  export default {
    name: "ProfitReportHead",
    components: {
      ToggleFilterButton,
      PlusIconSVG,
      MainButton,
      TotallyBlockHead,
    },

    props: [
      'showFilter',
      'adminTabs',
      'countFilterParams',
      'activeTab',
    ],

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      }
    },

    created() {

    },

    data() {
      return {
        isShowSearchEngineBlock: false,
        tab: this.activeTab,
        isModalChooseExpressOrderPopup: false,

      }
    },



    methods: {


      changeTab(val) {
        this.$emit('changeTab', val)
      },

      selectAll(val) {
        this.$emit('selectAll', val)
      },

      showSearchEngineBlock(){
        this.isShowSearchEngineBlock = !this.isShowSearchEngineBlock;
      },

      changeChooseExpressOrderPopup(val){
        this.isModalChooseExpressOrderPopup = val
      },

      changePeriod(val){
        this.activePeriod = val
      },

    }
  }
</script>

<style scoped>
  @media(max-width: 992px){
    .light-bg-block{
      display: flex;
      flex-direction: column-reverse;
      align-items: initial;
    }

    .totally-block-wrap{
      margin-top: 15px;
    }
  }
</style>

