<template>
  <modal
      @close="$emit('close')"
      class="custom-popup"
  >
    <template slot="header">
      From Transaction 3%
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="from-trans">
          <div class="from-trans__list">
            <div class="from-trans__item">
              <LinkButton
                  class="accent underline"
                  :value="'74583 $0.92'"
              />
            </div>
            <div class="from-trans__item">
              <LinkButton
                  class="accent underline"
                  :value="'74583 $0.92'"
              />
            </div>
            <div class="from-trans__item">
              <LinkButton
                  class="accent underline"
                  :value="'74583 $0.92'"
              />
            </div>
            <div class="from-trans__item">
              <LinkButton
                  class="accent underline"
                  :value="'74583 $0.92'"
              />
            </div>
            <div class="from-trans__item">
              <LinkButton
                  class="accent underline"
                  :value="'74583 $0.92'"
              />
            </div>
            <div class="from-trans__item">
              <LinkButton
                  class="accent underline"
                  :value="'74583 $0.92'"
              />
            </div>
            <div class="from-trans__item">
              <LinkButton
                  class="accent underline"
                  :value="'74583 $0.92'"
              />
            </div>
            <div class="from-trans__item">
              <LinkButton
                  class="accent underline"
                  :value="'74583 $0.92'"
              />
            </div>
            <div class="from-trans__item">
              <LinkButton
                  class="accent underline"
                  :value="'74583 $0.92'"
              />
            </div>
            <div class="from-trans__item">
              <LinkButton
                  class="accent underline"
                  :value="'74583 $0.92'"
              />
            </div>
          </div>
        </div>



        <div class="block-table__total site-line-total scoped mb-0">
          Total
          <span>$523</span>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import LinkButton from "../../../../UI/buttons/LinkButton/LinkButton";


  export default {
    name: "FromTransactionPopup",
    components: {
      Modal,
      LinkButton,
    },

    props: [
      'activeTab'
    ],

    data(){
      return{
        show1: true,
        show2: false,
      }
    }
  }

</script>

<style lang="scss" scoped>
  .from-trans{
    &__list{
      display: flex;
      flex-wrap: wrap;
      margin: 0 -4px;
    }
    &__item{
      padding: 0 4px;
      margin-bottom: 8px;
      width: 20%;
    }
  }

  .site-line-total.scoped{
    font-weight: bold;
    line-height: 30px;
    
    span{
      font-size: 40px;
      line-height: 47px;
    }
  }
</style>
