<template>
  <div>
    <ShippingReportHead
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeFilter="changeFilter"
        :showFilter="showFilter"
        :activeTab="activeTab"
        @changeTab="changeTab"
    />

    <div class="table-filter-wrap">

      <ShippingReportFilter
          :showFilter="showFilter"
          @close="closeFilter"
          @changeFilter="changeFilter"
          @resetFilter="$emit('resetFilter')"
      />

      <ShippingReportTable
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          :activeTab="activeTab"
      />
    </div>
  </div>
</template>

<script>


  import ShippingReportHead from "./ShippingReportHead/ShippingReportHead";
  import ShippingReportTable from "./ShippingReportTable/ShippingReportTable";
  import ShippingReportFilter from "./ShippingReportFilter/ShippingReportFilter";

  export default {
    name: "ShippingReportTableUser",

    components: {
      ShippingReportHead,
      ShippingReportTable,
      ShippingReportFilter,
    },

    props: [
      'typeShops',
      'filterGetParams',
      'countFilterParams',
    ],

    data(){
      return{
        showFilter: false,
        filterId: '',
        filterName: '',
        forPage: 10,
        page: 1,
        activeTab: 1,

        isModalCommentsPopup: '',
        isModalSendEmailPopup: '',
        isModalLastFiveIPPopup: '',
        isModalStatisticPopup: '',
      }
    },

    watch: {
      typeShops(newVal) {
        this.typeShops = newVal
      },
      filterGetParams(newVal) {
        this.filterGetParams = newVal
      },
      countFilterParams(newVal) {
        this.countFilterParams = newVal
      },
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeShopType(index) {
        this.$emit('changeShopType', index)
      },


      changeFilter(data) {
        this.$emit('changeFilter', data)
      },

      selectAll(val) {
        this.selectedNow = val
      },

      changeCommentsPopup(val){
        this.isModalCommentsPopup = val
      },
      changeSendEmailPopup(val){
        this.isModalSendEmailPopup = val
      },
      changeLastFiveIPPopup(val){
        this.isModalLastFiveIPPopup = val
      },
      changeStatisticPopup(val){
        this.isModalStatisticPopup = val
      },

      changeTab(id) {
        this.activeTab = id
      },
    }
  }
</script>

<style scoped>

</style>


