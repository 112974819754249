<template>
  <div class="fragment">

    <div class="site-table-wrap small-table">
      <table class="site-table" v-if="!isMobileFunc()">
        <thead>
        <tr>
          <th>Etsy ID</th>
          <th>Created</th>
          <th>Shipped</th>
          <th>Transaction #</th>
          <th width="100%" class="hide-1600"></th>
          <th class="text-lg-right">Transactions Sum, $</th>
          <th class="text-lg-right">Sklad USA Fee, $</th>
          <th class="text-lg-right">PayPal Fee, $</th>
          <th class="text-lg-right">Shipping, $</th>
          <th class="text-lg-right">Consolidation, $</th>
          <th class="text-lg-right">Profit, $</th>
          <th class="text-lg-right">Funds Available, $</th>
        </tr>
        </thead>
        <tbody>

        <tr>
          <td>
            <span class="table-link btn-style">#180378</span>
          </td>
          <td>27 Oct 2020</td>
          <td>
            <div class="table-row">
              <span class="table-ico">
								<img src="/img/company-icons-group/tab-fedex.svg" alt="ico">
							</span>
            </div>
          </td>
          <td>
            <div class="table-row">
              <span class="table-link btn-style">
                1R309976EP9541325
              </span>
            </div>
          </td>
          <td width="100" class="hide-1600"></td>
          <td align="right">
            100
          </td>
          <td align="right">
            1
          </td>
          <td align="right">
            1
          </td>
          <td align="right">
            <div class="table-row justify-content-end ">
              <v-popover
                  class="site-tooltip mw-220"
                  offset="5"
                  placement="top-left"
                  trigger="hover"
              >
                  <span class="site-link">
                    <span class="site-link brown text-decoration-underline" >
                      1
                    </span>
                  </span>
                <template slot="popover">
                  <div class="section-label mb-2">Consolidation Price, $</div>
                  <InputEditField
                      :placeholder="'1'"
                      :type="'text'"
                      :value="''"
                  />
                </template>
              </v-popover>
            </div>
          </td>
          <td align="right">
            1
          </td>
          <td align="right">
            10
          </td>
          <td align="right">
            10
          </td>
        </tr>
        <tr>
          <td>
            <span class="table-link btn-style">#180378</span>
          </td>
          <td>27 Oct 2020</td>
          <td>
            <div class="table-row">
              <span class="table-ico">
								<img src="/img/company-icons-group/tab-dhl.svg" alt="ico">
							</span>
            </div>
          </td>
          <td>
            <div class="table-row">
              <span class="table-link btn-style">
                1R309976EP9541325
              </span>
            </div>
          </td>
          <td width="100" class="hide-1600"></td>
          <td align="right">
            100
          </td>
          <td align="right">
            1
          </td>
          <td align="right">
            1
          </td>
          <td align="right">
            <div class="table-row justify-content-end">
              <v-popover
                  class="site-tooltip mw-220"
                  offset="5"
                  placement="top-left"
                  trigger="hover"
              >
                  <span class="site-link">
                    <LinkButton
                        :type="'edit'"
                        class="margin-ico-0 ttx-5px"
                    />
                  </span>
                <template slot="popover">
                  <div class="section-label mb-2">Consolidation Price, $</div>
                  <InputEditField
                      :placeholder="'10'"
                      :type="'text'"
                      :value="''"
                  />
                </template>
              </v-popover>
            </div>
          </td>
          <td align="right">
            1
          </td>
          <td align="right">
            10
          </td>
          <td align="right">
            10
          </td>
        </tr>
        </tbody>
      </table>

      <div class="table-card" v-if="isMobileFunc()">
        <div class="table-card__list">
          <div class="table-card__item">
            <div class="table-card__item-content"
                 :class="{show: show1}"
            >
              <div class="table-card__item-head">
                <div class="table-card__item-head-label">
                  <div class="table-card__item-head-label-i">
                    <img src="/img/company-icons-group/tab-fedex.svg" alt="ico">
                  </div>
                </div>
                <div class="table-card__item-number ">
                  #180378
                </div>
              </div>
              <transition name="slide">
                <div class="table-card__item-body"
                     v-if="show1"
                >
                  <div class="table-card__item-row custom-row">
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Created
                      </div>
                      <div class="table-card__item-info">
                        27 Oct 2020
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col">
                      <div class="table-card__item-label">
                        Transaction #
                      </div>
                      <div class="table-card__item-info">
                        <span class="table-link btn-style">
                          1R309976EP9541325
                        </span>
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Transactions Sum, $
                      </div>
                      <div class="table-card__item-info">
                        10
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Sklad USA Fee, $
                      </div>
                      <div class="table-card__item-info ">
                        1
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        PayPal Fee, $
                      </div>
                      <div class="table-card__item-info ">
                        1
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Shipping, $
                      </div>
                      <div class="table-card__item-info ">
                        1
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Consolidation, $
                      </div>
                      <div class="table-card__item-info ">
                        1
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Profit, $
                      </div>
                      <div class="table-card__item-info ">
                        10
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Funds Available, $
                      </div>
                      <div class="table-card__item-info ">
                        10
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
              <div class="table-card__item-footer">
                <div class="table-card__item-show"
                     @click="show1 = !show1"
                >
                  Show More
                </div>
              </div>
            </div>
          </div>
          <div class="table-card__item">
            <div class="table-card__item-content"
                 :class="{show: show2}"
            >
              <div class="table-card__item-head">
                <div class="table-card__item-head-label">
                  <div class="table-card__item-head-label-i">
                    <img src="/img/company-icons-group/tab-dhl.svg" alt="ico">
                  </div>
                </div>
                <div class="table-card__item-number ">
                  #180378
                </div>
              </div>
              <transition name="slide">
                <div class="table-card__item-body"
                     v-if="show2"
                >
                  <div class="table-card__item-row custom-row">
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Created
                      </div>
                      <div class="table-card__item-info">
                        27 Oct 2020
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col">
                      <div class="table-card__item-label">
                        Transaction #
                      </div>
                      <div class="table-card__item-info">
                        <span class="table-link btn-style">
                          1R309976EP9541325
                        </span>
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Transactions Sum, $
                      </div>
                      <div class="table-card__item-info">
                        10
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Sklad USA Fee, $
                      </div>
                      <div class="table-card__item-info ">
                        1
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        PayPal Fee, $
                      </div>
                      <div class="table-card__item-info ">
                        1
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Shipping, $
                      </div>
                      <div class="table-card__item-info ">
                        <v-popover
                            class="site-tooltip mw-220"
                            offset="5"
                            placement="top-left"
                            trigger="hover"
                        >
                  <span class="site-link">
                    <LinkButton
                        :type="'edit'"
                    />
                  </span>
                          <template slot="popover">
                            <div class="section-label mb-2">Consolidation Price, $</div>
                            <InputEditField
                                :placeholder="'10'"
                                :type="'text'"
                                :value="''"
                            />
                          </template>
                        </v-popover>
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Consolidation, $
                      </div>
                      <div class="table-card__item-info ">
                        1
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Profit, $
                      </div>
                      <div class="table-card__item-info ">
                        10
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Funds Available, $
                      </div>
                      <div class="table-card__item-info ">
                        10
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
              <div class="table-card__item-footer">
                <div class="table-card__item-show"
                     @click="show2 = !show2"
                >
                  Show More
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="table-bottom-btn">
      <div class="table-bottom-btn__inner">
        <ShowMore :count="'25'"/>
        <ExportBtn class="table-bottom-btn__right"/>
      </div>
    </div>

  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import InputEditField from "../../../../../../UI/inputs/InputEditField/InputEditField";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";


  export default {
    name: "ShippingReportTable",

    components: {
      // MainButton,
      // DefaultInput,
      ShowMore,
      ExportBtn,
      InputEditField,
      LinkButton,
    },

    mixins: [mixinDetictingMobile],

    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        show1: false,
        show2: false,
        show3: false,
      }
    },


    methods: {},

  }
</script>

<style lang="scss" scoped>

  .table-row.justify-content-end {
    /*transform: translateX(7px);*/
  }

  .ttx-5px{
    transform: translateX(5px)
  }

  .mw-220 {
    max-width: 220px !important;
  }
</style>
