import { render, staticRenderFns } from "./ProfitReportTransactionsTable.vue?vue&type=template&id=0383f27a&scoped=true"
import script from "./ProfitReportTransactionsTable.vue?vue&type=script&lang=js"
export * from "./ProfitReportTransactionsTable.vue?vue&type=script&lang=js"
import style0 from "./ProfitReportTransactionsTable.vue?vue&type=style&index=0&id=0383f27a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0383f27a",
  null
  
)

export default component.exports