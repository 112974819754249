<template>
  <div class="fragment">
    <ProfitReportTransactionsHead/>

    <div class="detail-page">
      <div class="detail-page__left">
        <CardLeftBlock
            class="clear-992 height-search-engine"
            :backgroundImage="'balance'"
        >
          <template slot="body">
            <div class="profit-report">
              <div class="profit-report__caption"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                    'profitReport_Transaction',
                    'profitReport_orderFba',
                    'profitReport_orderFbm',
                    'profitReport_fundsWithdrowal',
                    'profitReport_disposal',
                    'profitReport_expense',
                    'profitReport_income',
                    'profitReport_insurance',
                    'profitReport_otherInvoice',
                    'profitReport_packagingFba',
                    'profitReport_packagingFbm',
                    'profitReport_return',
                ])"></div>
                <span v-if="type === 'transaction'">{{$t('profitReport_Transaction.localization_value.value')}}</span>
                <span v-if="type === 'order_fba'">{{$t('profitReport_orderFba.localization_value.value')}}</span>
                <span v-if="type === 'order_fbm'">{{$t('profitReport_orderFbm.localization_value.value')}}</span>
                <span v-if="type === 'balance_output'">{{$t('profitReport_fundsWithdrowal.localization_value.value')}}</span>
                <span v-if="type === 'disposal'">{{$t('profitReport_disposal.localization_value.value')}}</span>
                <span v-if="type === 'expense'">{{$t('profitReport_expense.localization_value.value')}}</span>
                <span v-if="type === 'income'">{{$t('profitReport_income.localization_value.value')}}</span>
                <span v-if="type === 'insurance'">{{$t('profitReport_insurance.localization_value.value')}}</span>
                <span v-if="type === 'other_invoice'">{{$t('profitReport_otherInvoice.localization_value.value')}}</span>
                <span v-if="type === 'packaging_fba'">{{$t('profitReport_packagingFba.localization_value.value')}}</span>
                <span v-if="type === 'packaging_fbm'">{{$t('profitReport_packagingFbm.localization_value.value')}}</span>
                <span v-if="type === 'return'">{{$t('profitReport_return.localization_value.value')}}</span>

              </div>
              <div class="profit-report__title">
                ${{parseFloat(total).toFixed(2)}}
              </div>
            </div>
          </template>
        </CardLeftBlock>
      </div>

      <div class="detail-page__right">
        <ProfitReportTransactionsTable
            v-if="list.length > 0"
            :list="list"
            :type="type"
        />
      </div>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import ProfitReportTransactionsHead from "./ProfitReportTransactionsHead/ProfitReportTransactionsHead";
  import ProfitReportTransactionsTable from "./ProfitReportTransactionsTable/ProfitReportTransactionsTable";

  export default {
    name: "ProfitReportTransactionsAdmin",
    components: {
      CardLeftBlock,
      ProfitReportTransactionsHead,
      ProfitReportTransactionsTable,
    },

    props: {
      list: Array,
      total: Number,
      type: String,
    },

    computed: {

    },

    data(){
      return{
        options: [{}],
        date: '',
      }
    },

    methods: {

    },
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../scss/mixins/mixins";
  @import "../../../../../../scss/colors";

  .profit-report{
    max-width: 400px;
    width: 100%;

    &__caption{
      font-size: 14px;
      line-height: 21px;
      color: $borderBrown;
    }

    &__title{
      color: $black;
      font-weight: bold;
      line-height: 149.69%;
      font-size: 48px;

      @include for-992{
        font-size: 24px;
        margin-bottom: 20px;
        line-height: normal;
      }
    }

    &__dp{
      border-radius: 6px;
      background: white;
    }
  }


</style>
