<template>
  <div class="fragment">
    <div class="custom-row list-items">


<!--      <template v-if="partList.length > 0">-->
<!--        <template v-for="(item,index) in partList">-->
<!--          <div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;sm-50 mb-2"-->
<!--               v-show="(!showAll && index < 50) || showAll"-->
<!--               :key="index">-->
<!--            <a :href="getLintByType(item)" target="_blank" class="link-btn accent underline">-->
<!--              #{{item.id}} ${{item.amount}}-->
<!--            </a>-->
<!--          </div>-->
<!--          <span v-if="partList.length - 1 === index" :key="'a' + index">zaza{{index}}</span>-->
<!--        </template>-->

<!--        <template v-for="(item,index) in partList2">-->
<!--          <div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;sm-50 mb-2"-->
<!--               v-show="showAll"-->
<!--               :key="index">-->
<!--            <a :href="getLintByType(item)" target="_blank" class="link-btn accent underline">-->
<!--              #{{item.id}} ${{item.amount}}-->
<!--            </a>-->
<!--          </div>-->
<!--          <span v-if="partList2.length - 1 === index" :key="'a' + index">zaza{{index}}</span>-->
<!--        </template>-->
<!--      </template>-->

<!--      <template v-else>-->
      <template v-for="(itemList,indexList) in list">
        <template v-if="indexList === 0">
          <template v-for="(item,index) in itemList">
            <div class="custom-col custom-col--16 custom-col--sm-50 mb-2"
                 v-if="(!showAll && index < 50 && indexList === 0) || showAll"
                 :key="index + 'list' + indexList">
              <a :href="getLintByType(item)" target="_blank" class="link-btn accent underline">
                #{{item.id}} ${{item.amount}}
              </a>
            </div>
          </template>
        </template>
      </template>
      <template v-for="(itemList,indexList) in list">
        <template v-if="indexList === 1">
          <template v-for="(item,index) in itemList">
            <div class="custom-col custom-col--16 custom-col--sm-50 mb-2"
                 v-if="showAll"
                 :key="index + 'list' + indexList">
              <a :href="getLintByType(item)" target="_blank" class="link-btn accent underline">
                #{{item.id}} ${{item.amount}}
              </a>
            </div>
          </template>
        </template>
      </template>
<!--        <template v-for="(item,index) in list">-->
<!--         -->
<!--        </template>-->
<!--      </template>-->

    </div>
<!--    <div class="cursor-pointer" style="font-size: 37px" v-if="showAllBtn" @click="showAll = true; showAllBtn = false;"> Show All </div>-->
    <div class="export-btn btn-style wfc"
         style="margin-top: 20px; padding-top: 20px;"
         v-if="showAllBtn"
         v-bind:class="{'export-btn--loading': loadingContent}"
    >
      <div @click="showAllList" class="export-btn__txt">
        Show All
      </div>
      <div class="export-btn__list"></div>
    </div>


    <FromTransactionPopup
      v-if="isModalFromTransactionPopup"
      @close="changeFromTransactionPopup(false)"
    />
  </div>
</template>

<script>
  // import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import FromTransactionPopup from "../../../../popups/FromTransactionPopup/FromTransactionPopup";
  import {FBMMixinsHelper} from "@/mixins/FBMMixins/FBMMixins";
  import {INVOICES_TYPES} from "@/staticData/staticVariables";

  export default {
    name: "ProfitReportTransactionsTable",
    components: {
      // LinkButton,
      FromTransactionPopup,
    },

    mixins: [FBMMixinsHelper],

    props: {
      list: Array,
      type: String,
    },

    computed: {

    },

    data(){
      return{
        isModalFromTransactionPopup: false,

        partList: [],
        partList2: [],

        showAll: false,
        showAllBtn: false,
        loadingContent: false,
      }
    },

    mounted() {
      // console.log(this.list);
      // if(Object.hasOwnProperty.call(this.list, '0')){
      //   console.log(this.list[0].length);
      //   console.log(this.list[0]);
      //   this.partList = this.list[0]
      // }
      if(Object.hasOwnProperty.call(this.list, '0')){
        if(this.list[0].length > 50) {
          this.showAllBtn = true
        }
      }

      // if(this.list.length > 50) {
      //   this.showAllBtn = true
      // } else {
      //   this.showAll = true
      // }
      //
      // if(this.list.length > 100000) {
      //   this.partList = this.list.slice(0, 100000)
      //   this.partList2 = this.list.slice(100000)
      // }
      // if(this.list.length > 30000) {
      //   this.partList = this.list.slice(0, 30000)
      //   this.partList2 = this.list.slice(30000)
      // }
    },

    methods: {
      showAllList() {
        this.loadingContent = true
        setTimeout(() => {
          this.showAll = true
          this.showAllBtn = false
        },10)
      },

      changeFromTransactionPopup(val){
        this.isModalFromTransactionPopup = val
      },

      getFilterTypeInvoiceById(item) {
        switch (item.invoice_type_id) {
          case INVOICES_TYPES.INVOICES_TYPE_ORDER_FBM.id :
            return 'orders'
          case INVOICES_TYPES.INVOICES_TYPE_ORDER_FBA.id :
            return 'FBA'
          case INVOICES_TYPES.INVOICES_TYPE_ORDER.id :
            if(item.model_namespace.indexOf('OrderExpress') > -1){
              return 'DHLFedExTNT'
            } else {
              return 'inbound'
            }
          case INVOICES_TYPES.INVOICES_TYPE_STORAGE_USAGE.id :
            return 'other'
          case INVOICES_TYPES.INVOICES_TYPE_DISPOSAL.id :
            return 'other'
          case INVOICES_TYPES.INVOICES_TYPE_CUSTOM.id :
            return 'other'
          case INVOICES_TYPES.INVOICES_TYPE_LISTING.id :
            return 'other'
        }
      },


      getLintByType(item) {
        switch (this.type){
          case 'transaction' :
            return this.$store.getters.GET_PATHS.cusPayments + '?order=' + item.id + '&type=' + item.payment_system
          // case 'order_fba' :
          //   return this.$store.getters.GET_PATHS.ordersFBA + '/show/' + item.id
          // case 'order_fbm' :
          //   return this.$store.getters.GET_PATHS.ordersFBM + '/show/' + this.getOrderFBMTypeById(item.order_type_id).name + '/' + item.id
          case 'order_fba' :
            return this.$store.getters.GET_PATHS.financeInvoices + '?type=FBA&id=' + item.id
          case 'order_fbm' :
            return this.$store.getters.GET_PATHS.financeInvoices + '?type=orders&id=' + item.id
          case 'order_express_dhl' :
            return this.$store.getters.GET_PATHS.financeInvoices + '?type=DHLFedExTNT&id=' + item.id
          case 'order_express_fedex' :
            return this.$store.getters.GET_PATHS.financeInvoices + '?type=DHLFedExTNT&id=' + item.id
          case 'order_express_tnt' :
            return this.$store.getters.GET_PATHS.financeInvoices + '?type=DHLFedExTNT&id=' + item.id
          case 'balance_output' :
            return this.$store.getters.GET_PATHS.financeRequestFunds + '?id=' + item.id
          case 'payoneer_commission' :
            return this.$store.getters.GET_PATHS.financeRequestFunds + '?id=' + item.id
          case 'disposal' :
            return this.$store.getters.GET_PATHS.warehouseDisposalOrders + '?id=' + item.id
          case 'expense' :
            return this.$store.getters.GET_PATHS.expenses + '?id=' + item.id
          case 'income' :
            return this.$store.getters.GET_PATHS.otherIncome + '?id=' + item.id
          case 'insurance' :
            return this.$store.getters.GET_PATHS.financeInvoices + `?type=${this.getFilterTypeInvoiceById(item)}&id=` + item.id
          case 'skladusa_insurance' :
            return this.$store.getters.GET_PATHS.financeInvoices + `?type=${this.getFilterTypeInvoiceById(item)}&id=` + item.id
          case 'other_invoice' :
            return this.$store.getters.GET_PATHS.financeInvoices + '?type=other&id=' + item.id
          case 'other_packing_invoice' :
            return this.$store.getters.GET_PATHS.financeInvoices + '?type=other&id=' + item.id
          case 'packaging_fba' :
            return this.$store.getters.GET_PATHS.financeInvoices + '?type=FBA&id=' + item.id
          case 'packaging_fbm' :
            return this.$store.getters.GET_PATHS.financeInvoices + '?type=orders&id=' + item.id
          case 'return' :
            return this.$store.getters.GET_PATHS.financeInvoices + '?type=inbound&id=' + item.id
        }
        return '2'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .list-items{
    column-count: 4;
  }

  @import "../../../../../../../scss/colors";
  @import "../../../../../../../scss/mixins/mixins";

  .export-btn{
    position: relative;
    padding: 3px 5px;

    &__txt{
      position: relative;
      padding-right: 20px;
      padding-left: 27px;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: $accent;
      transition: 0.3s;

      @include for-768{
        font-size: 14px;
      }

      &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 1px;
        display: block;
        width: 17px;
        height: 17px;
        background: url("../../../../../../../assets/img/common/export-btn.svg") center center no-repeat;
      }

      &:after{
        content: "";
        position: absolute;
        right: 0;
        top: 7px;
        display: block;
        width: 12px;
        height: 6px;
        background: url("../../../../../../../assets/img/common/arr-brown-light.svg") center center no-repeat;
      }
    }

    &--loading{
      filter: grayscale(1);
      pointer-events: none;
    }

    &__list {
      position: absolute;
      bottom: 100%;
      right: 0;
      width: 100%;
      background-color: $mainBg;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__item {
      padding: 8px 5px;
      background-color: $mainBg;
      color: $accent;
      font-weight: bold;
      transition: 0.3s;

      &:hover {
        background-color: $bgTableEven;
      }
    }
  }
</style>
