<template>
  <ProfitReportTotalAdmin
      v-if="Object.keys(dataReports).length > 0"
      :dataReports="dataReports"
      :dataLeftBlock="dataLeftBlock"
      @filter="filter"
  />
</template>

<script>
  import ProfitReportTotalAdmin from "./ProfitReportTotalAdmin/ProfitReportTotalAdmin";
  import {queryFilterMixin} from "@/mixins/routeFilterMixins/queryFilterMixin";
  import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";

  export default {
    name: "ProfitReportTotal",
    components: {
      ProfitReportTotalAdmin,
    },

    mixins: [queryFilterMixin, routeFilter],

    data() {
      return {
        dataReports: {},
        dataLeftBlock: {
          filterType: 2,
          filterDate: [
            // this.$moment(new Date()).subtract(1, 'months').format("MM/DD/YY"),
            this.$moment(new Date()).startOf('month').format("MM/DD/YY"),
            this.$moment(new Date()).format("MM/DD/YY")
          ],
          filterDateStart: this.$moment(new Date()).subtract(1, 'months').format("MM/DD/YY"),
          filterDateEnd: this.$moment(new Date()).format("MM/DD/YY"),
        },

      }
    },

    mounted() {
      this.filter()
    },

    methods: {
      filter(change = false) {
        let myQuery = this.createQueryBuilderObject()

        let query = this.$route.query
        if((!query?.start_date && !query?.end_date) || change){
          delete query['start_date']
          delete query['end_date']
          this.$router.push({ path: '', query: {
              'start_date': this.$moment(this.dataLeftBlock.filterDate[0]).format('YYYY-MM-DD'),
              'end_date': this.$moment(this.dataLeftBlock.filterDate[1]).format('YYYY-MM-DD'),
            }})
        } else {
          this.dataLeftBlock.filterDate = []
          this.dataLeftBlock.filterDate.push(this.$moment(query?.start_date).format("MM/DD/YY"))
          this.dataLeftBlock.filterDate.push(this.$moment(query?.end_date).format("MM/DD/YY"))
        }

        let url = this.generateGetParamsFromQuery(myQuery, 1000, 0)

        url += `&with_details=0&start_date=${this.$route.query.start_date}&end_date=${this.$route.query.end_date}`

        this.$store.dispatch('fetchProfitReport', url).then(response => {
          this.dataReports = this.getRespData(response)
        })
      },

    }

  }
</script>

<style scoped>

</style>