var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('ProfitReportTransactionsHead'),_c('div',{staticClass:"detail-page"},[_c('div',{staticClass:"detail-page__left"},[_c('CardLeftBlock',{staticClass:"clear-992 height-search-engine",attrs:{"backgroundImage":'balance'}},[_c('template',{slot:"body"},[_c('div',{staticClass:"profit-report"},[_c('div',{staticClass:"profit-report__caption",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'profitReport_Transaction',
                  'profitReport_orderFba',
                  'profitReport_orderFbm',
                  'profitReport_fundsWithdrowal',
                  'profitReport_disposal',
                  'profitReport_expense',
                  'profitReport_income',
                  'profitReport_insurance',
                  'profitReport_otherInvoice',
                  'profitReport_packagingFba',
                  'profitReport_packagingFbm',
                  'profitReport_return',
              ])}}}),(_vm.type === 'transaction')?_c('span',[_vm._v(_vm._s(_vm.$t('profitReport_Transaction.localization_value.value')))]):_vm._e(),(_vm.type === 'order_fba')?_c('span',[_vm._v(_vm._s(_vm.$t('profitReport_orderFba.localization_value.value')))]):_vm._e(),(_vm.type === 'order_fbm')?_c('span',[_vm._v(_vm._s(_vm.$t('profitReport_orderFbm.localization_value.value')))]):_vm._e(),(_vm.type === 'balance_output')?_c('span',[_vm._v(_vm._s(_vm.$t('profitReport_fundsWithdrowal.localization_value.value')))]):_vm._e(),(_vm.type === 'disposal')?_c('span',[_vm._v(_vm._s(_vm.$t('profitReport_disposal.localization_value.value')))]):_vm._e(),(_vm.type === 'expense')?_c('span',[_vm._v(_vm._s(_vm.$t('profitReport_expense.localization_value.value')))]):_vm._e(),(_vm.type === 'income')?_c('span',[_vm._v(_vm._s(_vm.$t('profitReport_income.localization_value.value')))]):_vm._e(),(_vm.type === 'insurance')?_c('span',[_vm._v(_vm._s(_vm.$t('profitReport_insurance.localization_value.value')))]):_vm._e(),(_vm.type === 'other_invoice')?_c('span',[_vm._v(_vm._s(_vm.$t('profitReport_otherInvoice.localization_value.value')))]):_vm._e(),(_vm.type === 'packaging_fba')?_c('span',[_vm._v(_vm._s(_vm.$t('profitReport_packagingFba.localization_value.value')))]):_vm._e(),(_vm.type === 'packaging_fbm')?_c('span',[_vm._v(_vm._s(_vm.$t('profitReport_packagingFbm.localization_value.value')))]):_vm._e(),(_vm.type === 'return')?_c('span',[_vm._v(_vm._s(_vm.$t('profitReport_return.localization_value.value')))]):_vm._e()]),_c('div',{staticClass:"profit-report__title"},[_vm._v(" $"+_vm._s(parseFloat(_vm.total).toFixed(2))+" ")])])])],2)],1),_c('div',{staticClass:"detail-page__right"},[(_vm.list.length > 0)?_c('ProfitReportTransactionsTable',{attrs:{"list":_vm.list,"type":_vm.type}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }