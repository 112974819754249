import { render, staticRenderFns } from "./ProfitReportTotal.vue?vue&type=template&id=773c0530&scoped=true"
import script from "./ProfitReportTotal.vue?vue&type=script&lang=js"
export * from "./ProfitReportTotal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "773c0530",
  null
  
)

export default component.exports